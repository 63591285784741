import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  useMediaQuery,
} from '@chakra-ui/react';

const PdfModal = ({ isOpen, onClose, pdfUrl, docTitle }) => {
  // Detectar si el dispositivo es móvil o no
  const [isMobile] = useMediaQuery('(max-width: 768px)');

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      // Eliminamos 'size' para permitir que 'ModalContent' controle el ancho
    >
      <ModalOverlay backdropFilter="blur(4px)" />
      <ModalContent
        bg="white"
        color="black"
        maxW={{ base: 'calc(100% - 14px)', md: '750px' }} // Ancho máximo ajustado
        mx="auto" // Centrar el modal horizontalmente
        p={{ base: 4, md: 6 }} // Padding responsive
        border="2px solid black" // Borde negro de 2px sólido
        fontFamily="'Space Mono', monospace" // Fuente 'Space Mono'
      >
        <ModalHeader
          fontSize={{ base: 'md', md: 'lg' }} // Tamaño de fuente responsive
          textAlign="center"
        >
          {docTitle || 'Documento PDF'}
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} /> {/* Eliminamos el borde azul */}
        <ModalBody>
          {isMobile ? (
            <Flex direction="column" align="center" justify="center">
              <Text mb={4} fontSize={{ base: 'sm', md: 'md' }}>
                No se puede visualizar el PDF directamente en este dispositivo.
              </Text>
              <Button
                as="a"
                href={pdfUrl}
                target="_blank"
                rel="noopener noreferrer"
                colorScheme="blue"
                fontFamily="'Space Mono', monospace" // Fuente 'Space Mono'
              >
                Abrir PDF en nueva pestaña
              </Button>
            </Flex>
          ) : (
            <iframe
              src={pdfUrl}
              width="100%"
              height="600px"
              style={{ border: 'none' }}
              title={docTitle}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default PdfModal;
