import { FaCalendarAlt, FaClock, FaMapMarkerAlt } from 'react-icons/fa';

export const docContent = {
  "1.1 IDEA": [
		"Teníamos que diseñar una web para contar que Jungle es sede oficial de la Barcelona Design Week. ",
"¿Una web dirigida a diseñadores? Una web pensada para que la entiendan los diseñadores."
],

    "1.2 POR_QUÉ_JUNGLE_(PDF)": [
        "Aquí podríamos incluir un breve texto de introducción acerca de la importancia del diseño para Jungle y nuestra apuesta por el diseño en todas sus formas para construir un futuro mejor.",
"Después podemos hacer un breve resumen contando que este año hemos decidido ser una de las sedes oficiales de la Barcelona Design Week, acogiendo eventos, charlas y demás. "
      ],
  "2.1 INFO_FECHAYHORA_(PDF)": [
    "Damn! I'm Researcher es el evento clave de la investigación en diseño. Dos jornadas donde expertos en empresas líderes como Caixabank, Veed, Travel Perk, Telefónica, Media Markt entre otras discutirán sobre cómo la investigación mejora la comprensión del usuario, impulsa la innovación y optimiza los procesos de diseño.",
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaCalendarAlt style={{ marginRight: '5px' }} /> <b>Fecha:</b> 17 y 18 de octubre
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaClock style={{ marginRight: '5px' }} /> <b>Horario Jueves:</b> 15:30h a 20:00h
      </span>
    </>,
        <>
       <span style={{ display: 'flex', alignItems: 'center' }}>
       <FaClock style={{ marginRight: '5px' }} /> <b>Horario Viernes:</b> 9:00h a 14:00h
     </span>
    </>,
        <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaMapMarkerAlt style={{ marginRight: '5px' }} /> <b>Ubicación:</b> Jungle BCN (Àlaba 111)
      </span>
    </>
  ],
  "3.1 INFO_FECHAYHORA_(PDF)": [
    "Charla de Stef Silva, directora creativa de Invisible, en el opening de la Barcelona Design Week",
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaCalendarAlt style={{ marginRight: '5px' }} /> <b>Fecha:</b> 18 de octubre
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaClock style={{ marginRight: '5px' }} /> <b>Hora:</b> por definir
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaMapMarkerAlt style={{ marginRight: '5px' }} /> <b>Ubicación:</b> Auditori Design Hub
      </span>
    </>
  ],
  "3.5.1 INFO_FECHAYHORA_(PDF)": [
    "Un brunch futurista",
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaCalendarAlt style={{ marginRight: '5px' }} /> <b>Fecha:</b> 22 de octubre
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaClock style={{ marginRight: '5px' }} /> <b>Hora:</b> 9 a 12 horas
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaMapMarkerAlt style={{ marginRight: '5px' }} /> <b>Ubicación:</b> Jungle BCN (Àlaba 111)
      </span>
    </>
  ],
  "4.1 INFO_FECHAYHORA_(PDF)": [
    "Un punto de encuentro para las personas interesadas en diseño industrial. Una jornada en la que se va a explorar esta disciplina desde diferentes ángulos y perspectivas. Invitados e invitadas con roles de diseño en empresas y agencias como Fairphone, Grohe, Dyson, Revolut, Kiska, Accenture Song, TCL, La Granja, Shad, Wallbox, entre otrxs, compartirán sus experiencias y conocimiento en charlas, ponencias magistrales, mesas redondas y mucho networking.",
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaCalendarAlt style={{ marginRight: '5px' }} /> <b>Fecha:</b> 24 octubre
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaClock style={{ marginRight: '5px' }} /> <b>Hora:</b> 11 am
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaMapMarkerAlt style={{ marginRight: '5px' }} /> <b>Ubicación:</b> Jungle BCN (Àlaba 111)
      </span>
    </>
  ],
  "4.2 PROGRAMA_EVENTO_(HTML)": [
    "Programa del evento con detalles sobre las conferencias y charlas que se llevarán a cabo:",
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>11:00h Main conference</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0', }} />
        <span>Patrick Speck, Global Design Leader en LIXIL (GROHE & American Standard). English</span>
      </div>
    </>,
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>12:00h Live interview</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0',  }} />
        <span>Miquel Ballester, Head of Product Management en Fairphone.</span>
      </div>
    </>,
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>13:30h Brunch</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0',  }} />
      </div>
    </>,
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>14:30h Short Talks</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0',  }} />
        <ul style={{ marginLeft: '20px', listStyleType: 'disc' }}>
          <li>Damian Calvo (Revolut, ex Dyson)</li>
          <li>Marc Capdevila (Kiska)</li>
          <li>Tiago Abreu (ex TCL)</li>
          <li>Mario Sánchez (Redbility)</li>
        </ul>
      </div>
    </>,
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>16:30h Break</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0',  }} />
      </div>
    </>,
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>17:00h Expert Panel</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0',  }} />
        <span>Diseñar en agencia vs Diseñar en empresa</span>
      </div>
    </>,
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>17:45h Expert Panel</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0', }} />
        <span>¿Cómo afrontar el proceso de diseño en la era de la IA. Artesanía, tecnología o las dos?</span>
      </div>
    </>,
    <>
      <div style={{ marginBottom: '8px' }}>
        <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>18:30h - 21:00h</span>
        <hr style={{ border: '1px solid #ccc', margin: '4px 0',  }} />
        <span>Afterwork at JungleBcn</span>
      </div>
    </>
   ],

  "4.4.1.1 INFO_PATRICK_SPECK_(PDF)": [
    "Patrick Speck (LIXIL)  Main Conference",
    "Patrick Speck es actualmente el Líder de Diseño Global de LIXIL para EMENA y lidera los estudios de diseño de Düsseldorf y Londres. Ha ocupado roles senior en diseño en Electrolux y GROHE, donde lideró importantes proyectos y transformaciones de marca. A lo largo de su carrera, ha contribuido a diseños galardonados, incluyendo varios premios Red Dot y iF Design."
  ],
  "4.4.2.1 INFO_MIQUEL_BALLESTER_(PDF)": [
    "Miquel Ballester (Fairphone)  Live interview",
    "Miquel Ballester es Head of Product Management y cofundador de Fairphone. Tiene formación en ingeniería de diseño industrial y diseño para la sostenibilidad. Su pasión particular es utilizar la creación de productos como un artefacto para el cambio, esforzándose por diseñar productos que no solo sean más sostenibles, sino que también permitan un cambio sistémico."
  ],
  "4.4.3.1 INFO_DAMIÁN_CALVO_(PDF)": [
    "Damián Calvo (Revolut)  Short Talk",
    "Damián Calvo, actual Jefe de Diseño Industrial en Revolut, cuenta con una amplia trayectoria diseñando para empresas internacionales que van desde pequeñas startups hasta gigantes tecnológicos como Samsung o Dyson."
  ],
  "4.4.4.1 INFO_MARC_CAPDEVILA_(PDF)": [
    "Marc Capdevila (KISKA)  Short Talk",
    "Marc Capdevila Sitri es un diseñador especializado en transporte, con experiencia en empresas como KISKA y Volkswagen. Ha realizado varias estancias profesionales en diseño de transporte y exterior en Europa, y desde 2019 trabaja como diseñador en KISKA, agencia de diseño global con sede en Austria."
  ],
  "4.4.5.1 INFO_TIAGO_ABREU_(PDF)": [
    "Tiago Abreu (Former TCL Electronics) Short Talk",
    "Tiago es un profesional experimentado con una sólida formación en diseño e innovación. Ha ocupado puestos de liderazgo como responsable de innovación en diseño en TCL Electronics, cofundador de BioCheck-In y, más recientemente, fundó el Elevate Design Studio en Shenzhen, China."
  ],
  "4.4.6.1 INFO_MARIO_SÁNCHEZ_(PDF)": [
    "Mario Sánchez (Redbility)  Short Talk",
    "CEO de Redbility y Partner de JUNGLE. Con más de dos décadas de experiencia en el sector, ha liderado equipos e impulsando proyectos de alto impacto y colaborando con grandes marcas como Inditex, BBVA, Ferrovial, Telefónica, ACCIONA, Roca y Nestlé."
  ],
  "4.4.7.1 INFO_CAROLINA_VILLALOBO_(PDF)": [
    "Carolina Villalobos (Accenture Song) Panel: Diseñar en agencia vs Diseñar en empresa",
    "Carolina es Design Lead & Manager en Accenture Song, especializada en experiencias de usuario que combinan productos físicos y digitales. En China, trabajó para WILDDESIGN y designaffairs, colaborando con importantes clientes. Posteriormente, se unió a NIO como Diseñadora Industrial Senior."
  ],
  "4.4.8.1 INFO_ROEL_GEERTS_(PDF)": [
    "Roel Geerts (former HP) Panel: Diseñar en agencia vs Diseñar en empresa",
    "Persona orientada a las personas, dedicada al diseño integrado con el negocio y el desarrollo. Diseñador experto en Cx, Ux/UI y experiencia como líder, estratega y gerente de diseño."
  ],
  "4.4.9.1 INFO_ÀLEX_CASABÒ_(PDF)": [
    "Àlex Casabò (ANIMA Design) Panel: ¿Cómo afrontar el proceso de diseño en la era de la IA.  Artesanía, tecnología o las dos?",
    "Àlex Casabò es un ingeniero en diseño industrial freelance en Barcelona, especializado en la visualización y renderizado de productos en 3D. Tras graduarse en Elisava en 2013, ha colaborado con varios estudios y clientes en diversas disciplinas."
  ],
  "4.4.10.1 INFO_ALEIX_VALLS_(PDF)": [
    "Aleix Valls (Liquid Lab) Panel: ¿Cómo afrontar el proceso de diseño en la era de la IA.  Artesanía, tecnología o las dos?",
    "Aleix Valls es el CEO y fundador de Liquid Lab, una consultora de transformación digital. Con una formación en matemáticas e ingeniería, ha ocupado cargos destacados en investigación, consultoría e innovación. Fue director general de la Mobile World Capital Barcelona."
  ],
  "5.1 INFO_FECHAYHORA_(PDF)": [
    "Jungle organiza la fiesta de cierre de la Barcelona Design Week junto con Grupo Simon y la escuela de diseño y moda LCI. ",
"Entrada libre." ,
  <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaCalendarAlt style={{ marginRight: '5px' }} /> <b>Fecha:</b> 25 de octubre.
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaClock style={{ marginRight: '5px' }} /> <b>Hora:</b> 19 a 22 horas
      </span>
    </>,
    <>
      <span style={{ display: 'flex', alignItems: 'center' }}>
        <FaMapMarkerAlt style={{ marginRight: '5px' }} /> <b>Ubicación:</b> Exterior. Esquina Carrer d’Àlaba I Carrer de Sancho de Ávila 	
      </span>
    </>
  ],

};
