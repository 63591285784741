import React from 'react';
import { Box, Grid, GridItem, Flex, Image, IconButton, Button, useColorMode, useMediaQuery } from '@chakra-ui/react';
import { SunIcon, MoonIcon } from '@chakra-ui/icons';

const Header = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [isMobile] = useMediaQuery("(max-width: 768px)");

  return (
    <Box p={isMobile ? 1 : 4} bg={colorMode === 'dark' ? '#000' : 'gray.100'}>
      <Grid templateColumns={isMobile ? "75% 25%" : "repeat(2, 1fr)"} gap={4}>
        <GridItem>
          <Flex alignItems="center">
            {/* Cambia la imagen dependiendo del modo de color */}
            <Image
              src={colorMode === 'light' ? '/IMAGES/logo-black.svg' : '/IMAGES/logo-white.svg'}
              alt="Logo"
              mt={3}
              ml={3}
              width={isMobile ? "90px" : "40px"} // Usando width directamente
            />
          </Flex>
        </GridItem>
        <GridItem textAlign="right">
          <Flex alignItems="center" justifyContent="flex-end">
            {isMobile ? (
              <IconButton
                icon={colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                onClick={toggleColorMode}
                isRound
                mt={isMobile ? "3" : "1"}
                mr={isMobile ? "7" : "0"}
                boxSize="24px"
                bg={colorMode === 'dark' ? 'white' : 'black'}
                color={colorMode === 'dark' ? 'black' : 'white'}
                aria-label="Toggle color mode"
                transition="transform 0.2s ease-in-out"
                _hover={{ transform: 'scale(1.1)' }}
                _focus={{ transform: 'scale(1.1)' }}
              />
            ) : (
              <Button
                mt={isMobile ? "3" : "3"}
                onClick={toggleColorMode}
                bg={colorMode === 'dark' ? 'white' : 'black'}
                color={colorMode === 'dark' ? 'black' : 'white'}
                borderRadius="25px"
                px={4}
                h={'30px'}
                transition="transform 0.2s ease-in-out"
                _hover={{ transform: 'scale(1.1)' }}
                _focus={{ transform: 'scale(1.1)' }}
              >
                {colorMode === 'light' ? <MoonIcon mr={2} /> : <SunIcon mr={2} />}
                <span>{colorMode === 'light' ? 'Dark' : 'Light'} Mode</span>
              </Button>
            )}
          </Flex>
        </GridItem>
      </Grid>
    </Box>
  );
};

export default Header;
