// App.js

import React, { useState, useEffect } from 'react';
import {
  ChakraProvider,
  useMediaQuery,
  Box,
  ColorModeScript,
  keyframes,
  usePrefersReducedMotion,
  useColorMode,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Button,
  Text,
} from '@chakra-ui/react';
import Header from './components/Header';
import FileList from './components/FileList';
import FileListMobile from './components/FileListMobile';
import Preloader from './components/Preloader'; // Importamos el Preloader
import theme from './theme';
import './App.css';
import CookieConsent from 'react-cookie-consent'; // Importamos react-cookie-consent

function App() {
  const [isMobile] = useMediaQuery('(max-width: 768px)');
  const [loading, setLoading] = useState(false); // Estado de carga inicializado en false
  const { colorMode } = useColorMode(); // Hook para detectar el modo de color
  const [isModalOpen, setIsModalOpen] = useState(false); // Estado para controlar el modal
  const [cookiesAccepted, setCookiesAccepted] = useState(false); // Estado para el consentimiento de cookies

  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    // Verificamos si el preloader ya se ha mostrado en la sesión actual
    const hasSeenPreloader = sessionStorage.getItem('hasSeenPreloader');

    if (!hasSeenPreloader) {
      setLoading(true);
      const timer = setTimeout(() => {
        setLoading(false);
        sessionStorage.setItem('hasSeenPreloader', 'true');
      }, 2000);

      return () => clearTimeout(timer); // Limpiamos el timer al desmontar el componente
    }
  }, []);

  // Añadimos el código de Google Analytics
  useEffect(() => {
    if (cookiesAccepted) {
      // Solo cargamos GA si el usuario aceptó las cookies
      if (process.env.NODE_ENV === 'production') {
        // Cargamos el script de Google Analytics
        const script = document.createElement('script');
        script.async = true;
        script.src =
          'https://www.googletagmanager.com/gtag/js?id=G-4SBRYXB9FD';
        document.head.appendChild(script);

        // Inicializamos Google Analytics
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        window.gtag = gtag; // Hacemos gtag disponible globalmente
        gtag('js', new Date());
        gtag('config', 'G-4SBRYXB9FD', {
          page_path: window.location.pathname,
        });
      }
    }
  }, [cookiesAccepted]);

  // Seguimiento de vistas de página en SPA (aunque sea una sola página)
  useEffect(() => {
    if (cookiesAccepted && process.env.NODE_ENV === 'production') {
      // En una aplicación de una sola página, esto solo se ejecutará una vez
      window.gtag('event', 'page_view', {
        page_path: window.location.pathname,
      });
    }
  }, [cookiesAccepted]);

  const fadeIn = keyframes`
    from { opacity: 0; }
    to { opacity: 1; }
  `;

  const animation = prefersReducedMotion
    ? undefined
    : `${fadeIn} 0.5s ease-in-out`;

  return (
    <>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme}>
        {loading ? (
          <Preloader />
        ) : (
          <Box overflowX="hidden" animation={animation}>
            {isMobile && <Header />}
            {isMobile ? <FileListMobile /> : <FileList />}

            {/* Aviso de Cookies en la parte inferior */}
            <CookieConsent
              location="bottom"
              buttonText="Aceptar"
              style={{
                background: colorMode === 'dark' ? '#fff' : '#000',
                color: colorMode === 'dark' ? '#000' : '#fff',
                borderTop: `2px solid ${
                  colorMode === 'dark' ? '#fff' : '#000'
                }`,
                padding: '15px',
              }}
              buttonStyle={{
                color: colorMode === 'dark' ? '#000' : '#fff',
                fontSize: '13px',
                backgroundColor: colorMode === 'dark' ? '#fff' : '#000',
                border: `1px solid ${colorMode === 'dark' ? '#000' : '#fff'}`,
              }}
              expires={365}
              onAccept={() => setCookiesAccepted(true)} // Actualizamos el estado al aceptar
            >
              Este sitio web utiliza cookies para mejorar la experiencia del
              usuario.{' '}
              <Button
                variant="link"
                onClick={() => setIsModalOpen(true)}
                color={colorMode === 'dark' ? '#000' : '#fff'}
                textDecoration="underline"
              >
                Más información
              </Button>
            </CookieConsent>

            {/* Modal para mostrar información de cookies */}
            <Modal
              isOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
              size="lg"
            >
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>Política de Cookies</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <Text fontSize="sm" mb={4}>
                    <strong>Actualizado:</strong> 20/9/2024
                  </Text>
                  <Text fontSize="md" mb={4}>
                    <strong>¿Qué son las cookies?</strong>
                    <br />
                    Las cookies y tecnologías similares son pequeños archivos de
                    texto o fragmentos de código que se descargan en su
                    dispositivo cuando visita un sitio web. Ayudan al sitio web
                    a reconocer su dispositivo y recordar información sobre su
                    visita.
                  </Text>
                  <Text fontSize="md" mb={4}>
                    <strong>¿Por qué utilizamos cookies?</strong>
                    <br />
                    Utilizamos cookies para mejorar y optimizar el sitio web,
                    medir el tráfico y comprender cómo los usuarios interactúan
                    con el sitio.
                  </Text>
                  <Text fontSize="md" mb={4}>
                    <strong>Tipos de cookies que utilizamos:</strong>
                    <br />
                    <strong>Cookies necesarias:</strong> Estas cookies son
                    esenciales para el funcionamiento del sitio web.
                    <br />
                    <strong>Cookies funcionales:</strong> Permiten funcionalidades
                    mejoradas y personalización.
                    <br />
                    <strong>Cookies de análisis:</strong> Nos ayudan a entender
                    cómo los visitantes interactúan con el sitio web.
                  </Text>
                  <Text fontSize="md" mb={4}>
                    <strong>¿Cómo puedo desactivar o eliminar las cookies?</strong>
                    <br />
                    Puede configurar su navegador para rechazar cookies o
                    eliminar cookies existentes. Tenga en cuenta que esto puede
                    afectar la funcionalidad del sitio web.
                  </Text>
                </ModalBody>
              </ModalContent>
            </Modal>
          </Box>
        )}
      </ChakraProvider>
    </>
  );
}

export default App;
