// FileList.js

import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  GridItem,
  IconButton,
  List,
  ListItem,
  useColorMode,
  Collapse,
  Badge,
  Button,
  useDisclosure,
  Text,
  Image,
  Flex,
} from '@chakra-ui/react';
import {
  ChevronRightIcon,
  ChevronDownIcon,
  ArrowDownIcon,
  SunIcon,
  MoonIcon,
  LinkIcon,
} from '@chakra-ui/icons';
import {
  FiFolder,
  FiFileText,
  FiMusic,
  FiFilm,
  FiImage,
  FiFile,
} from 'react-icons/fi';
import { FaLinkedin } from 'react-icons/fa';
import { files } from './filesData'; // Listado de ficheros
import ModalVideo from './ModalVideo'; // Modal para Videos
import ModalImage from './ModalImage'; // Modal para Imágenes
import ModalAudio from './ModalAudio'; // Modal para Audios
import ModalText from './ModalText'; // Modal para Documentos
import PdfModal from './PdfModal'; // Modal para PDFs
import { docContent } from './docContentData'; // Contenido de Documentos

const FileList = () => {
  const { colorMode, toggleColorMode } = useColorMode();
  const [openFolders, setOpenFolders] = useState({});
  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentVideoTitle, setCurrentVideoTitle] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [currentDocContent, setCurrentDocContent] = useState(null);
  const [currentDocTitle, setCurrentDocTitle] = useState(null);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [currentPdfTitle, setCurrentPdfTitle] = useState(null);

  const {
    isOpen: isVideoOpen,
    onOpen: onVideoOpen,
    onClose: onVideoClose,
  } = useDisclosure();
  const {
    isOpen: isImageOpen,
    onOpen: onImageOpen,
    onClose: onImageClose,
  } = useDisclosure();
  const {
    isOpen: isAudioOpen,
    onOpen: onAudioOpen,
    onClose: onAudioClose,
  } = useDisclosure();
  const {
    isOpen: isDocOpen,
    onOpen: onDocOpen,
    onClose: onDocClose,
  } = useDisclosure();
  const {
    isOpen: isPdfOpen,
    onOpen: onPdfOpen,
    onClose: onPdfClose,
  } = useDisclosure();

  // Abrir carpetas de primer nivel por defecto
  useEffect(() => {
    const initialOpenFolders = {};
    files.forEach((_, index) => {
      initialOpenFolders[index] = true;
    });
    setOpenFolders(initialOpenFolders);
  }, []);

  const toggleFolder = (path) => {
    setOpenFolders((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const getIcon = (type) => {
    switch (type) {
      case 'folder':
        return FiFolder;
      case 'Audio':
        return FiMusic;
      case 'Video':
        return FiFilm;
      case 'Image':
        return FiImage;
      case 'Document':
      case 'PDF':
        return FiFileText;
      case 'Web':
        return LinkIcon;
      case 'Linkedin':
        return FaLinkedin;
      case 'Presentation':
        return FiFile;
      default:
        return FiFileText;
    }
  };

  const handleItemClick = (e, file, path) => {
    if (e.target.tagName === 'BUTTON') return;

    e.preventDefault();
    if (file.type === 'folder') {
      toggleFolder(path);
    } else if (file.type === 'Video') {
      handleVideoClick(file.link, file.title);
    } else if (file.type === 'Image') {
      handleImageClick(e, file.link);
    } else if (file.type === 'Audio') {
      handleAudioClick(file.link);
    } else if (file.type === 'PDF') {
      handlePdfClick(file.link, file.title);
    } else if (file.type === 'Document') {
      handleDocClick(file.title);
    } else if (
      file.type === 'Web' ||
      file.type === 'Linkedin' ||
      file.type === 'Presentation'
    ) {
      window.open(file.link, '_blank');
    }
  };

  const handleVideoClick = (videoUrl, videoTitle) => {
    setCurrentVideo(videoUrl);
    setCurrentVideoTitle(videoTitle);
    onVideoOpen();
  };

  const handleImageClick = (e, imageUrl) => {
    e.preventDefault();
    setCurrentImage(imageUrl);
    onImageOpen();
  };

  const handleAudioClick = (audioUrl) => {
    setCurrentAudio(audioUrl);
    onAudioOpen();
  };

  const handleDocClick = (docTitle) => {
    setCurrentDocContent(docContent[docTitle] || []);
    setCurrentDocTitle(docTitle);
    onDocOpen();
  };

  const handlePdfClick = (pdfUrl, pdfTitle) => {
    setCurrentPdfUrl(pdfUrl);
    setCurrentPdfTitle(pdfTitle);
    onPdfOpen();
  };

  const renderFiles = (fileArray, path = '', level = 0) => {
    return fileArray.map((file, index) => {
      const newPath = path ? `${path}-${index}` : `${index}`;
      console.log(`Nivel: ${level}, Título: ${file.title}`); // Log para depuración

      return (
        <Box key={newPath}>
          <ListItem
            p={2}
            borderTopWidth={index === 0 ? '1px' : '0'}
            borderBottomWidth="1px"
            borderColor={colorMode === 'dark' ? 'gray.600' : 'gray.200'}
            bg={
              level === 0
                ? colorMode === 'dark'
                  ? '#0e0e0e'
                  : 'gray.50'
                : colorMode === 'dark'
                ? '#000000'
                : 'gray.50'
            }
            color={colorMode === 'dark' ? 'whiteAlpha.900' : 'black'}
            _hover={{
              bg: colorMode === 'dark' ? '#202020' : 'gray.200',
              color: colorMode === 'dark' ? '#f1ff9b' : 'black',
            }}
            fontSize="0.9em"
            fontFamily="'Space Mono', monospace"
            cursor="pointer"
            onClick={(e) => handleItemClick(e, file, newPath)}
          >
            <Grid templateColumns="3fr 1fr 1fr 1fr 1fr" alignItems="center">
              {/* Ajustamos la primera columna a 3fr y el desplazamiento */}
              <GridItem pl={`${level * 45}px`}>
                <Flex alignItems="center">
                  {file.type === 'folder' ? (
                    <>
                      <IconButton
                        icon={
                          openFolders[newPath] ? (
                            <ChevronDownIcon />
                          ) : (
                            <ChevronRightIcon />
                          )
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                          toggleFolder(newPath);
                        }}
                        variant="ghost"
                        size="sm"
                        mr={2}
                        boxSize="14px"
                        color={colorMode === 'dark' ? 'white' : 'gray.600'}
                      />
                      {file.subfiles && file.subfiles.length > 0 && (
                        <Text
                          display="inline-block"
                          mr={2}
                          fontSize="sm"
                          color={
                            colorMode === 'dark'
                              ? 'whiteAlpha.700'
                              : 'gray.600'
                          }
                        >
                          {file.subfiles.length}
                        </Text>
                      )}
                    </>
                  ) : (
                    <IconButton
                      as={getIcon(file.type)}
                      variant="ghost"
                      size="sm"
                      mr={2}
                      boxSize="14px"
                      color={colorMode === 'dark' ? 'white' : 'gray.600'}
                    />
                  )}
                  <Text
                    as="span"
                    color={
                      colorMode === 'dark' ? 'whiteAlpha.900' : 'blue.500'
                    }
                    fontWeight={level === 0 ? 'bold' : 'normal'}
                  >
                    {file.title}
                  </Text>
                </Flex>
              </GridItem>
              <GridItem>
                <Badge
                  borderRadius="5px"
                  fontWeight="light"
                  colorScheme="gray"
                  p={1}
                  fontSize="0.9em"
                  fontFamily="'Space Mono', monospace"
                  color={
                    colorMode === 'dark' ? 'whiteAlpha.600' : 'gray.600'
                  }
                >
                  {file.date}
                </Badge>
              </GridItem>
              <GridItem>
                <Badge
                  borderRadius="5px"
                  fontWeight="light"
                  colorScheme="gray"
                  p={1}
                  fontSize="0.9em"
                  fontFamily="'Space Mono', monospace"
                  color={
                    colorMode === 'dark' ? 'whiteAlpha.600' : 'gray.600'
                  }
                >
                  {file.size}
                </Badge>
              </GridItem>
              <GridItem>
                <Badge
                  borderRadius="5px"
                  fontWeight="light"
                  colorScheme="gray"
                  p={1}
                  fontSize="0.9em"
                  fontFamily="'Space Mono', monospace"
                  color={
                    colorMode === 'dark' ? 'whiteAlpha.600' : 'gray.600'
                  }
                >
                  {file.type}
                </Badge>
              </GridItem>
              <GridItem textAlign="right">
                {file.download && (
                  <Button
                    color={colorMode === 'dark' ? 'white' : 'black'}
                    bg={colorMode === 'dark' ? 'black' : 'white'}
                    borderColor={colorMode === 'dark' ? 'white' : 'black'}
                    borderWidth="1px"
                    variant="outline"
                    size="sm"
                    as="a"
                    textTransform="uppercase"
                    href={file.link}
                    download
                    leftIcon={<ArrowDownIcon />}
                    onClick={(e) => e.stopPropagation()}
                    _hover={{
                      bg: colorMode === 'dark' ? 'white' : 'black',
                      color: colorMode === 'dark' ? 'black' : 'white',
                      borderColor:
                        colorMode === 'dark' ? 'white' : 'black',
                    }}
                    _focus={{
                      boxShadow: 'none',
                    }}
                  >
                    Download
                  </Button>
                )}
              </GridItem>
            </Grid>
          </ListItem>
          {file.subfiles && (
            <Collapse in={openFolders[newPath]}>
              <List mt={0} spacing={0}>
                {renderFiles(file.subfiles, newPath, level + 1)}
              </List>
            </Collapse>
          )}
        </Box>
      );
    });
  };

  return (
    <Box
      p={4}
      bg={colorMode === 'dark' ? '#000' : 'gray.100'}
      overflowX="auto" // Permitimos scroll horizontal si es necesario
    >
      <List spacing={0}>
        {/* Encabezado de la tabla */}
        <ListItem
          p={2}
          borderBottomWidth="1px"
          borderColor={colorMode === 'dark' ? 'gray.600' : 'gray.200'}
          bg={colorMode === 'dark' ? '#000' : 'gray.100'}
          color={colorMode === 'dark' ? 'whiteAlpha.900' : 'black'}
          fontWeight="bold"
          fontSize="0.9em"
          fontFamily="'Space Mono', monospace"
        >
          <Grid templateColumns="3fr 1fr 1fr 1fr 1fr" alignItems="center">
            {/* Ajustamos la primera columna a 3fr */}
            <GridItem>
              <Image
                src={
                  colorMode === 'light'
                    ? '/IMAGES/logo-black.svg'
                    : '/IMAGES/logo-white.svg'
                }
                alt="Logo"
                width="100px"
              />
            </GridItem>
            <GridItem>
              <Text fontSize="1.15em" fontFamily="'Space Mono', monospace">
                DATE ADDED
              </Text>
            </GridItem>
            <GridItem>
              <Text fontSize="1.15em" fontFamily="'Space Mono', monospace">
                SIZE
              </Text>
            </GridItem>
            <GridItem>
              <Text fontSize="1.15em" fontFamily="'Space Mono', monospace">
                KIND
              </Text>
            </GridItem>
            <GridItem textAlign="right">
              <Flex alignItems="center" justifyContent="flex-end">
                <Button
                  onClick={toggleColorMode}
                  bg={colorMode === 'dark' ? 'white' : 'black'}
                  color={colorMode === 'dark' ? 'black' : 'white'}
                  borderRadius="25px"
                  px={2}
                  h={'30px'}
                  transition="transform 0.2s ease-in-out"
                  _hover={{ transform: 'scale(1.1)' }}
                  _focus={{ transform: 'scale(1.1)' }}
                  aria-label="Toggle color mode"
                >
                  {colorMode === 'light' ? <MoonIcon /> : <SunIcon />}
                </Button>
              </Flex>
            </GridItem>
          </Grid>
        </ListItem>
        {/* Listado de archivos */}
        {renderFiles(files)}
      </List>

      {/* Modales */}
      <ModalVideo
        isOpen={isVideoOpen}
        onClose={onVideoClose}
        videoUrl={currentVideo}
        videoTitle={currentVideoTitle}
      />
      <ModalImage
        isOpen={isImageOpen}
        onClose={onImageClose}
        imageUrl={currentImage}
      />
      <ModalAudio
        isOpen={isAudioOpen}
        onClose={onAudioClose}
        audioUrl={currentAudio}
      />
      <ModalText
        isOpen={isDocOpen}
        onClose={onDocClose}
        docContent={currentDocContent}
        docTitle={currentDocTitle}
      />
      <PdfModal
        isOpen={isPdfOpen}
        onClose={onPdfClose}
        pdfUrl={currentPdfUrl}
        docTitle={currentPdfTitle}
      />
    </Box>
  );
};

export default FileList;
