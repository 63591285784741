// ModalImage.js

import React, { useState, useEffect } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Image,
  VStack,
  Box,
  Spinner,
  Center,
  useBreakpointValue,
  Fade,
} from '@chakra-ui/react';

const ModalImage = ({ isOpen, onClose, imageUrl, imageTitle }) => {
  const [isLoaded, setIsLoaded] = useState(false); // Estado para controlar la carga de la imagen

  // Reiniciar el estado de carga cada vez que cambia la imagen o se abre el modal
  useEffect(() => {
    if (isOpen) {
      setIsLoaded(false);
    }
  }, [imageUrl, isOpen]);

  // Ajustar el tamaño del modal basado en el estado de carga
  const modalSize = useBreakpointValue({ base: 'xl', md: '2xl' });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={modalSize} // Tamaño dinámico basado en el tamaño de la pantalla
      closeOnOverlayClick={false} // Opcional: evitar cerrar el modal al hacer clic fuera
    >
      <ModalOverlay backdropFilter="blur(4px)" /> {/* Blur de 4px */}
      <ModalContent
        bg="black"
        color="white"
        maxW={{ base: 'calc(100% - 14px)', md: '80vw', lg: '60vw' }} // Ancho máximo responsive con márgenes
        mx="auto" // Centrar el modal horizontalmente
        p={{ base: 2, md: 4 }} // Padding responsive
        border="2px solid white" // Borde blanco de 2px sólido
        fontFamily="'Space Mono', monospace" // Fuente 'Space Mono'
        minH="300px" // Altura mínima para acomodar el spinner
        transition="height 0.5s ease-in-out" // Transición suave para cambios de altura
        overflow="hidden" // Evitar que el contenido desborde
      >
        <ModalHeader
          textAlign="center"
          fontSize={{ base: 'md', md: 'lg' }} // Tamaño de fuente responsive
        >
          {imageTitle || 'Imagen'}
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} /> {/* Eliminamos el borde azul */}
        <ModalBody>
          {imageUrl && (
            <VStack align="center" spacing={4}>
              {/* Caja envolvente para manejar la posición y tamaño de la imagen */}
              <Box
                position="relative"
                width="100%"
                maxH="70vh"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                {/* Imagen con opacidad y transición para el efecto fade-in */}
                <Fade in={isLoaded} transition={{ duration: 1.5 }}>
                  <Image
                    src={imageUrl}
                    alt={imageTitle || 'Imagen'}
                    maxW="100%"
                    maxH="70vh"
                    objectFit="contain"
                    opacity={isLoaded ? 1 : 0} // Opacidad condicional
                    transition="opacity 1.5s ease-in-out" // Transición más suave y prolongada
                    onLoad={() => setIsLoaded(true)} // Actualizar el estado cuando la imagen se carga
                    onError={() => {
                      setIsLoaded(true); // Para ocultar el spinner incluso si hay un error
                      // Opcional: Puedes manejar errores aquí, como mostrar una imagen de respaldo
                    }}
                  />
                </Fade>
                {/* Spinner mientras la imagen se está cargando */}
                {!isLoaded && (
                  <Center
                    position="absolute"
                    top="60%"
                    left="50%"
                    transform="translate(-50%, -50%)"
                    zIndex="1" // Asegura que el spinner esté por encima de la imagen
                  >
                    <Spinner
                      size="xl"
                      color="gray.300"
                      thickness="4px"
                      speed="0.65s"
                    />
                  </Center>
                )}
              </Box>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalImage;
