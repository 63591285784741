export const files = [
  {
    title: "INTRO_JUNGLE_BDW",
    date: "2024-09-01",
    size: "♾️",
    type: "folder",
    download: false,
    link: "#",
    subfiles: [
      { title: "1.1 IDEA", date: "2024-09-01", size: "5MB", type: "Document", download: false, link: "#" },
      { title: "1.2 POR_QUÉ_JUNGLE_(PDF)", date: "2024-09-01", size: "5MB", type: "Document", download: false, link: "#" },
      { title: "1.3 BDW_MÁS_INFO_(WEB)", date: "2024-09-01", size: "500KB", type: "Web", download: false, link: "https://barcelonadesignweek.com/es/" }
    ]
  },
  {
    title: "EVENTO_REDBILITY_BDW_17-18_10_24",
    date: "2024-10-17",
    size: "♾️",
    type: "folder",
    download: false,
    link: "#",
    subfiles: [
      { title: "2.1 INFO_FECHAYHORA_(PDF)", date: "2024-10-17", size: "1MB", type: "Document", download: false, link: "#" },
      { title: "2.2 PROGRAMA_PONENTES_(PDF)", date: "2024-10-17", size: "3MB", type: "Presentation", download: false, link: "https://docs.google.com/presentation/d/1ZM3L5jwqFLky52elzoI9DFebF4PQsmdQxXL0AJGQEnQ/pub?start=true&loop=false&delayms=3000" },
      {
        title: "2.3 DAMN_I'M_RESEARCHER_(MATERIAL_ADICIONAL)",
        date: "2024-10-17",
        size: "♾️",
        type: "folder",
        download: false,
        link: "#",
        subfiles: [
          { title: "2.3.1 DAMN_I'M_RESEARCHER_PPT_(PDF)", date: "2024-10-17", size: "10MB", type: "Presentation", download: false, link: "https://docs.google.com/presentation/d/1-PgZJgfIHHY_ZOAD8ikzC_i9c2rasa_zbLTPyOgt2-I/pub?start=true&loop=false&delayms=3000" },
          {
            title: "2.3.2 EDICIÓN_ANTERIOR",
            date: "2024-10-17",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              { title: "2.3.2.1 VÍDEO_EDICIÓN_ANTERIOR_(MP4)", date: "2024-10-17", size: "200MB", type: "Video", download: true, link: "/VIDEOS/damm-edicion-anterior.mp4" },
              { title: "2.3.2.2 DOCUMENTO_EDICIÓN_ANTERIOR_(PDF)", date: "2024-10-17", size: "5MB", type: "Presentation", download: false, link: "https://docs.google.com/presentation/d/1QmGLb1ubrJtIMDr4rwyjLa0XUPMmjK37POuZTETh9m0/pub?start=true&loop=false&delayms=3000" }
            ]
          },
          { title: "2.3.3 FOTOS_DAMN_I'M_RESEARCHER_(PDF)", date: "2024-10-17", size: "20MB", type: "Presentation", download: false, link: "https://docs.google.com/presentation/d/1Gy490tOzP5DOIKysor-ZhtQ3_Ma8voTu3HzS535pbac/pub?start=true&loop=false&delayms=3000" }
        ]
      },
      { title: "2.4 INSCRIPCIÓN_(WEB)", date: "2024-10-17", size: "500KB", type: "Web", download: false, link: "https://www.redbility.com/damn-im-researcher-2024/" },
      {
        title: "2.5 INFO_REDBILITY",
        date: "2024-10-17",
        size: "♾️",
        type: "folder",
        download: false,
        link: "#",
        subfiles: [
          { title: "2.5.1 REDBILITY_WEB", date: "2024-10-17", size: "500KB", type: "Web", download: false, link: "https://www.redbility.com/" },
          { title: "2.5.2 FOTO_EQUIPO_REDBILITY_(JPEG)", date: "2024-10-17", size: "2MB", type: "Image", download: true, link: "/IMAGES/foto-redbility.jpg" }
        ]
      }
    ]
  },
  {
    title: "EVENTO_INVISIBLE_BDW_18_10_24",
    date: "2024-10-18",
    size: "♾️",
    type: "folder",
    download: false,
    link: "#",
    subfiles: [
      { title: "3.1 INFO_FECHAYHORA_(PDF)", date: "2024-10-18", size: "1MB", type: "Document", download: false, link: "#" },
      { title: "3.2 STEF_SILVA_CREDENCIALES_DEF_(PDF)", date: "2024-10-18", size: "3MB", type: "PDF", download: true, link: "/DOCS/_Stef Silva _ Invisible_Credenciales_2024_DEF.pdf" },
      {
        title: "3.3 PODCAST_INVISIBLEROOM_(WEB)",
        date: "2024-10-18",
        size: "500KB",
        type: "folder",
        download: false,
        link: "#",
        subfiles: [
          { title: "3.3.1 EP_(01)_INVISBLEROOM_(URL)", date: "2024-10-18", size: "500KB", type: "Video", download: false, link: "https://www.youtube.com/watch?v=a0doG6dW4Rw" },
          { title: "3.3.2 EP_(02)_INVISBLEROOM_(URL)", date: "2024-10-18", size: "500KB", type: "Video", download: false, link: "https://www.youtube.com/watch?v=FHQO_gdaioM" }
        ]
      },
      {
        title: "3.4 INFO_INVISIBLE",
        date: "2024-10-18",
        size: "♾️",
        type: "folder",
        download: false,
        link: "#",
        subfiles: [
          { title: "3.4.1 INVISIBLE_WEB", date: "2024-10-18", size: "500KB", type: "Web", download: false, link: "https://invisiblelab.es/" },
          { title: "3.4.2 INVISIBLE_INFORMES_(WEB)", date: "2024-10-18", size: "500KB", type: "Web", download: false, link: "https://invisiblelab.es/informes/" },
          { title: "3.4.3 FOTO_EQUIPO_INVISIBLE_(JPEG)", date: "2024-10-18", size: "2MB", type: "Image", download: true, link: "/IMAGES/FotoEquipo_invisible00.jpg" }
        ]
      },
      {
        title: "3.5 LA_DECISIÓN_MÁS_INTELIGENTE_DE_LA_HISTORIA",
        date: "2024-10-22",
        size: "♾️",
        type: "folder",
        download: false,
        link: "#",
        subfiles: [
          { title: "3.5.1 INFO_FECHAYHORA_(PDF)", date: "2024-10-22", size: "1MB", type: "Document", download: false, link: "#" },
          { title: "3.5.2 INSCRIPCIÓN_MAIL", date: "2024-10-22", size: "500KB", type: "Web", download: false, link: "mailto:hello@invisiblelab.es" },
          {
            title: "3.5.3 LASCENASDE_INVISIBLE_(FOLDER)",
            date: "2024-10-22",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              { title: "3.5.3.1 FOTO_(01)_LASCENASDE_INVISIBLE_(JPEG)", date: "2024-10-22", size: "2MB", type: "Image", download: true, link: "/IMAGES/cena-invisible-1.jpg" },
              { title: "3.5.3.2 FOTO_(02)_LASCENASDE_INVISIBLE_(JPEG)", date: "2024-10-22", size: "2MB", type: "Image", download: true, link: "/IMAGES/cena-invisible-2.jpg" }
            ]
          }
        ]
      }
    ]
  },
  {
    title: "EVENTO_LÚCID_BDW_17-18_10_24",
date: "2024-10-24",
size: "♾️",
type: "folder",
download: false,
link: "#",
subfiles: [
  {
    title: "4.1 INFO_FECHAYHORA_(PDF)",
    date: "2024-10-24",
    size: "1MB",
    type: "Document",
    download: false,
    link: "#"
  },
  {
    title: "4.2 PROGRAMA_EVENTO_(HTML)",
    date: "2024-10-24",
    size: "500KB",
    type: "Document",
    download: true,
    link: "/DOCS/Programa_LUCID.pdf"
  },
  {
    title: "4.3 INSCRIPCION_ENLACE",
    date: "2024-10-24",
    size: "♾️",
    type: "Web",
    download: false,
    link: "https://www.eventbrite.es/e/entradas-industrial-design-day-by-lucid-1009106824677?aff=oddtdtcreator",
  },
  {
    title: "4.4 INFO_PONENTES",
    date: "2024-10-24",
    size: "♾️",
    type: "folder",
    download: false,
    link: "#",
    subfiles: [
          {
            title: "4.4.1 PONENTE(01)PATRICK_SPECK",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.1.1 INFO_PATRICK_SPECK_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.1.2 FOTO_PATRICK_SPECK_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/00_Patrick Speck_Leader LIXIL Global Design EMENA.jpg",
              },
              {
                title: "4.4.1.3 LINKEDIN_PATRICK_SPECK_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/patrick-speck-b238bb1/",
              }
            ]
          },
          {
            title: "4.4.2 PONENTE(02)MIQUEL_BALLESTER",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.2.1 INFO_MIQUEL_BALLESTER_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.2.2 FOTO_MIQUEL_BALLESTER_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/01_Miquel Ballester_Fairphone.jpg",
              },
              {
                title: "4.4.2.3 LINKEDIN_MIQUEL_BALLESTER_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/miquelballester/",
              }
            ]
          },
          {
            title: "4.4.3 PONENTE(03)DAMIÁN_CALVO_(REVOLUT)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.3.1 INFO_DAMIÁN_CALVO_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.3.2 FOTO_DAMIÁN_CALVO_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/02_Damian Calvo_Revolut.png",
              },
              {
                title: "4.4.3.3 LINKEDIN_DAMIÁN_CALVO_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/damiancalvotome/",
              }
            ]
          },
          {
            title: "4.4.4 PONENTE(04)MARC_CAPDEVILA_(KISKA)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.4.1 INFO_MARC_CAPDEVILA_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.4.2 FOTO_MARC_CAPDEVILA_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/03_Marc Capdevila_KISKA.jpg",
              },
              {
                title: "4.4.4.3 LINKEDIN_MARC_CAPDEVILA_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/marccapdevila/",
              }
            ]
          },
          {
            title: "4.4.5 PONENTE(05)TIAGO_ABREU_(REVOLUT)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.5.1 INFO_TIAGO_ABREU_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.5.2 FOTO_TIAGO_ABREU_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/04_Tiago Abreu_TCL Electronics.jpg",
              },
              {
                title: "4.4.5.3 LINKEDIN_TIAGO_ABREU_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/tiago-pina-abreu/",
              }
            ]
          },
          {
            title: "4.4.6 PONENTE(06)MARIO_SÁNCHEZ_(REDBILITY)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.6.1 INFO_MARIO_SÁNCHEZ_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.6.2 FOTO_MARIO_SÁNCHEZ_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/05_Mario Sanchez_Redbility (1).jpg",
              },
              {
                title: "4.4.6.3 LINKEDIN_MARIO_SÁNCHEZ_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/mariosanchezgarcia/",
              }
            ]
          },
          {
            title: "4.4.7 PONENTE(07)CAROLINA_VILLALOBO_(ACCENTURE)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.7.1 INFO_CAROLINA_VILLALOBO_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.7.2 FOTO_CAROLINA_VILLALOBO_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/06_Carolina Villalobos_Accenture Song.jpg",
              },
              {
                title: "4.4.7.3 LINKEDIN_CAROLINA_VILLALOBO_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/carolina-villalobos/",
              }
            ]
          },
          {
            title: "4.4.8 PONENTE(08)ROEL_GEERTS_(HP)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.8.1 INFO_ROEL_GEERTS_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.8.2 FOTO_ROEL_GEERTS_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/Roel_Geerts.png",
              }
            ]
          },
          {
            title: "4.4.9 PONENTE(09)ÀLEX_CASABÒ_(ANIMA_DESIGN)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.9.1 INFO_ÀLEX_CASABÒ_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.9.2 LINKEDIN_ÀLEX_CASABÒ_(URL)",
                date: "2024-10-24",
                size: "500KB",
                type: "Linkedin",
                download: false,
                link: "https://www.linkedin.com/in/alexcasabo/",
              }
            ]
          },
          {
            title: "4.4.10 PONENTE(10)ALEIX_VALLS_(LIQUID_LAB)",
            date: "2024-10-24",
            size: "♾️",
            type: "folder",
            download: false,
            link: "#",
            subfiles: [
              {
                title: "4.4.10.1 INFO_ALEIX_VALLS_(PDF)",
                date: "2024-10-24",
                size: "2MB",
                type: "Document",
                download: false,
                link: "#",
              },
              {
                title: "4.4.10.2 FOTO_ALEIX_VALLS_(JPEG)",
                date: "2024-10-24",
                size: "1MB",
                type: "Image",
                download: true,
                link: "/IMAGES/08_Aleix Valls_Liquid Lab.jpg",
              }
            ]
          }
        ]
      },

      {
        "title": "4.5 LÚCID_SAVETHEDATE_(MP4)",
        "date": "2024-10-24",
        "size": "100MB",
        "type": "Video",
        "download": true,
        "link": "/VIDEOS/LUCID-STD.mp4"
      },
      {
        "title": "4.6 PODCAST_El_PERISCOPIO_(FOLDER)",
        "date": "2024-10-24",
        "size": "♾️",
        "type": "Web",
        "download": false,
        "link": "https://open.spotify.com/show/6yET5tVKZmbkJJRPODz9gF?si=3aa359b2a3ea4d10"
      },
      {
        "title": "4.7 LÚCID_DESIGN_TASTE_(FOLDER)",
        "date": "2024-10-24",
        "size": "♾️",
        "type": "Web",
        "download": false,
        "link": "https://drive.google.com/drive/folders/1r_Yu8JNUBH3agbI90cAAViVCwHngbvQR"
      },
      {
        "title": "4.8 INFO_LÚCID",
        "date": "2024-10-24",
        "size": "♾️",
        "type": "folder",
        "download": false,
        "link": "#",
        "subfiles": [
          {
            "title": "4.8.1 LÚCID_WEB",
            "date": "2024-10-24",
            "size": "500KB",
            "type": "Web",
            "download": false,
            "link": "https://www.lucid.pro/?gad_source=1&gclid=Cj0KCQjw0Oq2BhCCARIsAA5hubUUylIl9-aBp-LEsptprHFTUD_7YbGseAGhNRtcGAlJh7Eh8EVLWX4aAiXIEALw_wcB"
          },
          {
            "title": "4.8.2 FOTO_EQUIPO_LÚCID_(JPEG)",
            "date": "2024-10-24",
            "size": "1MB",
            "type": "Image",
            "download": true,
            "link": "/IMAGES/foto-lucid.jpg"
          }
        ]
      }
    ]
  },
      
  {
    title: "EVENTO_CLOSING_BDW_JUNGLE",
    date: "2024-10-25",
    size: "♾️",
    type: "folder",
    download: false,
    link: "#",
    subfiles: [
      { title: "5.1 INFO_FECHAYHORA_(PDF)", date: "2024-10-25", size: "1MB", type: "Document", download: false, link: "#" }
    ]
  }
];
