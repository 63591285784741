import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Text,
} from '@chakra-ui/react';

const ModalText = ({ isOpen, onClose, docContent, docTitle }) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      // Eliminamos 'size' para permitir que 'ModalContent' controle el ancho
    >
      <ModalOverlay backdropFilter="blur(4px)" /> {/* Blur de 4px */}
      <ModalContent
        bg="white"
        color="black"
        maxW={{ base: 'calc(100% - 14px)', md: '750px' }} // Ancho máximo ajustado
        mx="auto" // Centrar el modal horizontalmente
        p={{ base: 1, md: 3 }} // Padding responsive
        border="2px solid black" // Borde negro de 2px sólido
        fontFamily="'Space Mono', monospace" // Fuente 'Space Mono'
      >
        <ModalHeader
          fontSize={{ base: 'md', md: 'lg' }} // Tamaño de fuente responsive
          textAlign="center"
        >
          {docTitle || 'Documento'}
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} /> {/* Eliminamos el borde azul */}
        <ModalBody>
          {docContent ? (
            <VStack align="start" spacing={4}>
              {docContent.map((paragraph, index) => (
                <Text
                  key={index}
                  fontSize={{ base: 'sm', md: 'md' }} // Tamaño de fuente responsive
                >
                  {paragraph}
                </Text>
              ))}
            </VStack>
          ) : (
            <Text fontSize={{ base: 'sm', md: 'md' }}>
              No hay contenido disponible.
            </Text>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalText;
