import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
} from '@chakra-ui/react';

const ModalAudio = ({ isOpen, onClose, audioUrl }) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} size="lg" isCentered>
      <ModalOverlay />
      <ModalContent bg="black" color="white" maxW="80vw" maxH="50vh" p={4}>
        <ModalHeader>Audio Player</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {audioUrl && (
            <VStack align="center" spacing={4}>
              <audio controls style={{ width: '100%' }}>
                <source src={audioUrl} type="audio/mp3" />
                Your browser does not support the audio element.
              </audio>
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalAudio;
