import React, { useState, useEffect } from 'react';
import {
  Box,
  Text,
  Grid,
  GridItem,
  IconButton,
  List,
  ListItem,
  useColorMode,
  Collapse,
  useDisclosure,
  Flex,
  VStack,
  keyframes,
} from '@chakra-ui/react';
import { ChevronRightIcon, ChevronDownIcon, ArrowDownIcon } from '@chakra-ui/icons';
import {
  FiFolder,
  FiFileText,
  FiMusic,
  FiFilm,
  FiImage,
  FiLink,
  FiFile,
} from 'react-icons/fi'; // Agregamos FiFile para Presentation
import { FaLinkedin } from 'react-icons/fa';
import { files } from './filesData';
import ModalVideo from './ModalVideo';
import ModalImage from './ModalImage';
import ModalAudio from './ModalAudio';
import ModalText from './ModalText';
import PdfModal from './PdfModal'; // Importar el modal de PDFs
import { docContent } from './docContentData';

// Definir animación de aparición más liviana
const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const FileListMobile = () => {
  const { colorMode } = useColorMode();
  const [openFolders, setOpenFolders] = useState({});

  const {
    isOpen: isVideoOpen,
    onOpen: onVideoOpen,
    onClose: onVideoClose,
  } = useDisclosure();
  const {
    isOpen: isImageOpen,
    onOpen: onImageOpen,
    onClose: onImageClose,
  } = useDisclosure();
  const {
    isOpen: isAudioOpen,
    onOpen: onAudioOpen,
    onClose: onAudioClose,
  } = useDisclosure();
  const {
    isOpen: isDocOpen,
    onOpen: onDocOpen,
    onClose: onDocClose,
  } = useDisclosure();
  const {
    isOpen: isPdfOpen,
    onOpen: onPdfOpen,
    onClose: onPdfClose,
  } = useDisclosure();

  const [currentVideo, setCurrentVideo] = useState(null);
  const [currentVideoTitle, setCurrentVideoTitle] = useState(null);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentAudio, setCurrentAudio] = useState(null);
  const [currentDocContent, setCurrentDocContent] = useState(null);
  const [currentDocTitle, setCurrentDocTitle] = useState(null);
  const [currentPdfUrl, setCurrentPdfUrl] = useState(null);
  const [currentPdfTitle, setCurrentPdfTitle] = useState(null);

  useEffect(() => {
    const initialOpenFolders = {};
    files.forEach((_, index) => {
      if (_.type === 'folder') {
        initialOpenFolders[index] = true;
      }
    });
    setOpenFolders(initialOpenFolders);
  }, []);

  const toggleFolder = (path) => {
    setOpenFolders((prev) => ({
      ...prev,
      [path]: !prev[path],
    }));
  };

  const getIcon = (type) => {
    switch (type) {
      case 'folder':
        return FiFolder;
      case 'Audio':
        return FiMusic;
      case 'Video':
        return FiFilm;
      case 'Image':
        return FiImage;
      case 'Document':
      case 'PDF':
        return FiFileText;
      case 'Linkedin':
        return FaLinkedin;
      case 'Web':
        return FiLink;
      case 'Presentation':
        return FiFile;
      default:
        return FiFileText;
    }
  };

  const handleItemClick = (e, file, path) => {
    if (e.target.tagName === 'BUTTON') return;
    e.preventDefault();

    if (file.type === 'folder') {
      toggleFolder(path);
    } else if (file.type === 'Video') {
      setCurrentVideo(file.link);
      setCurrentVideoTitle(file.title);
      onVideoOpen();
    } else if (file.type === 'Image') {
      setCurrentImage(file.link);
      onImageOpen();
    } else if (file.type === 'Audio') {
      setCurrentAudio(file.link);
      onAudioOpen();
    } else if (file.type === 'PDF') {
      setCurrentPdfUrl(file.link);
      setCurrentPdfTitle(file.title);
      onPdfOpen();
    } else if (file.type === 'Document') {
      setCurrentDocContent(docContent[file.title] || []);
      setCurrentDocTitle(file.title);
      onDocOpen();
    } else if (['Web', 'Linkedin', 'Presentation'].includes(file.type)) {
      window.open(file.link, '_blank');
    }
  };

  const renderFiles = (fileArray, path = '', level = 0) => {
    return fileArray.map((file, index) => {
      const newPath = path ? `${path}-${index}` : `${index}`;

      return (
        <Box
          key={newPath}
          animation={`${fadeIn} 0.3s ease-out`} // Animación ligera de fadeIn
          style={{ animationFillMode: 'both' }}
        >
          <ListItem
            p={2}
            borderTopWidth={index === 0 ? '0' : '1px'}
            borderBottomWidth={index === fileArray.length - 1 ? '0' : '1px'}
            borderColor={colorMode === 'dark' ? 'gray.600' : 'gray.200'}
            bg={
              level === 0
                ? colorMode === 'dark'
                  ? '#0e0e0e'
                  : 'gray.100'
                : colorMode === 'dark'
                ? '#000000'
                : 'gray.100'
            }
            color={colorMode === 'dark' ? 'whiteAlpha.900' : 'black'}
            _hover={{
              bg:
                level === 0
                  ? colorMode === 'dark'
                    ? '#444444'
                    : 'gray.200'
                  : colorMode === 'dark'
                  ? '#202020'
                  : 'gray.200',
              color: colorMode === 'dark' ? '#f1ff9b' : 'black',
            }}
            fontSize="0.9em"
            fontFamily="'Space Mono', monospace"
            cursor="pointer"
            onClick={(e) => handleItemClick(e, file, newPath)}
          >
            <Grid templateColumns="85% 15%" alignItems="center">
              <GridItem>
                <Flex alignItems="center" pl={level * 4}>
                  {file.type === 'folder' ? (
                    <IconButton
                      icon={
                        openFolders[newPath] ? (
                          <ChevronDownIcon />
                        ) : (
                          <ChevronRightIcon />
                        )
                      }
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleFolder(newPath);
                      }}
                      variant="ghost"
                      size="sm"
                      mr={2}
                      boxSize="14px"
                      color={colorMode === 'dark' ? 'white' : 'gray.600'}
                    />
                  ) : (
                    <IconButton
                      as={getIcon(file.type)}
                      variant="ghost"
                      size="sm"
                      mr={2}
                      boxSize="14px"
                      color={colorMode === 'dark' ? 'white' : 'gray.600'}
                    />
                  )}
                  <VStack align="start" spacing={0} w="100%">
                    <Text
                      as="span"
                      color={
                        level === 0 && colorMode === 'dark'
                          ? 'whiteAlpha.900'
                          : colorMode === 'dark'
                          ? 'whiteAlpha.900'
                          : 'blue.500'
                      }
                      isTruncated
                      noOfLines={1}
                      maxW="100%"
                      fontWeight={level === 0 ? 'bold' : 'normal'} // Aplicamos negrita a los ítems de nivel 0
                    >
                      {file.title}
                    </Text>
                    <Text
                      fontSize="xs"
                      color={colorMode === 'dark' ? 'whiteAlpha.600' : 'gray.600'}
                    >
                      {file.date} | {file.type}
                    </Text>
                  </VStack>
                </Flex>
              </GridItem>
              <GridItem textAlign="right">
                {file.download && (
                  <IconButton
                    icon={<ArrowDownIcon />}
                    as="a"
                    href={file.link}
                    download
                    variant="outline"
                    color={colorMode === 'dark' ? 'white' : 'black'}
                    bg={colorMode === 'dark' ? 'black' : 'white'}
                    borderColor={colorMode === 'dark' ? 'white' : 'black'}
                    size="sm"
                    onClick={(e) => e.stopPropagation()}
                    _hover={{
                      bg: colorMode === 'dark' ? 'white' : 'black',
                      color: colorMode === 'dark' ? 'black' : 'white',
                      borderColor: colorMode === 'dark' ? 'white' : 'black',
                    }}
                    _focus={{
                      boxShadow: 'none',
                    }}
                  />
                )}
              </GridItem>
            </Grid>
          </ListItem>
          {file.subfiles && (
            <Collapse in={openFolders[newPath]}>
              <List pl={4} mt={0}>
                {renderFiles(file.subfiles, newPath, level + 1)}
              </List>
            </Collapse>
          )}
        </Box>
      );
    });
  };

  return (
    <Box p={4} bg={colorMode === 'dark' ? '#000' : 'gray.100'}>
      <List spacing={0}>
        {renderFiles(files)}
      </List>

      {/* Otros modales */}
      <ModalVideo
        isOpen={isVideoOpen}
        onClose={onVideoClose}
        videoUrl={currentVideo}
        videoTitle={currentVideoTitle}
      />
      <ModalImage
        isOpen={isImageOpen}
        onClose={onImageClose}
        imageUrl={currentImage}
      />
      <ModalAudio
        isOpen={isAudioOpen}
        onClose={onAudioClose}
        audioUrl={currentAudio}
      />
      <ModalText
        isOpen={isDocOpen}
        onClose={onDocClose}
        docContent={currentDocContent}
        docTitle={currentDocTitle}
      />
      <PdfModal
        isOpen={isPdfOpen}
        onClose={onPdfClose}
        pdfUrl={currentPdfUrl}
        docTitle={currentPdfTitle}
      />
    </Box>
  );
};

export default FileListMobile;
