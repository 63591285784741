// ./components/Preloader.js

import React from 'react';
import { Box, Image, Text, keyframes, useColorModeValue } from '@chakra-ui/react';

const fadeInZoom = keyframes`
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
`;

const Preloader = () => {
  // Obtenemos los valores según el modo de color actual
  const bgColor = useColorModeValue('white', 'black'); // Fondo blanco en light mode, negro en dark mode
  const logoSrc = useColorModeValue('/IMAGES/logo-black.svg', '/IMAGES/logo-white.svg'); // Logo según el tema
  const textColor = useColorModeValue('black', 'white'); // Color del texto

  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      height="100vh"
      bg={bgColor}
    >
      <Image
        src={logoSrc}
        alt="Logo"
        boxSize="150px"
        animation={`${fadeInZoom} 1.5s ease-in-out forwards`}
      />
   
    </Box>
  );
};

export default Preloader;
