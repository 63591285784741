import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  VStack,
  Box,
  Button,
  Icon,
} from '@chakra-ui/react';
import { FaYoutube } from 'react-icons/fa';

const ModalVideo = ({ isOpen, onClose, videoUrl, videoTitle }) => {
  // Función para verificar si la URL es de YouTube
  const isYouTubeUrl = (url) => {
    return url.includes('youtube.com') || url.includes('youtu.be');
  };

  // Función para obtener el ID de YouTube y armar la URL embebida
  const getYouTubeEmbedUrl = (url) => {
    const youtubeId = url.includes('youtube.com')
      ? new URLSearchParams(new URL(url).search).get('v') // Extraer el ID de youtube.com
      : url.split('/').pop(); // Extraer el ID de youtu.be
    return `https://www.youtube.com/embed/${youtubeId}`;
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      // Eliminamos 'size' para permitir que 'ModalContent' controle el ancho
    >
      <ModalOverlay backdropFilter="blur(4px)" /> {/* Blur de 4px */}
      <ModalContent
        bg="black"
        color="white"
        maxW={{ base: 'calc(100% - 14px)', md: '80vw', lg: '60vw' }} // Ancho máximo responsive con márgenes
        mx="auto" // Centrar el modal horizontalmente
        p={{ base: 2, md: 4 }} // Padding responsive
        border="2px solid white" // Borde blanco de 2px sólido
        fontFamily="'Space Mono', monospace" // Fuente 'Space Mono'
      >
        <ModalHeader
          textAlign="center"
          fontSize={{ base: 'md', md: 'lg' }} // Tamaño de fuente responsive
        >
          {videoTitle || 'Video'}
        </ModalHeader>
        <ModalCloseButton _focus={{ boxShadow: 'none' }} /> {/* Eliminamos el borde azul */}
        <ModalBody display="flex" justifyContent="center" alignItems="center">
          {videoUrl && (
            <VStack align="center" spacing={4} w="100%">
              <Box
                position="relative"
                paddingBottom="56.25%" // Mantener relación de aspecto 16:9
                height="0"
                width="100%"
                overflow="hidden"
              >
                {isYouTubeUrl(videoUrl) ? (
                  // Si es un video de YouTube, usar iframe
                  <iframe
                    src={getYouTubeEmbedUrl(videoUrl)}
                    title="YouTube video player"
                    frameBorder="0"
                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  ></iframe>
                ) : (
                  // Si es un video local, usar la etiqueta video
                  <video
                    controls
                    style={{
                      position: 'absolute',
                      top: 0,
                      left: 0,
                      width: '100%',
                      height: '100%',
                    }}
                  >
                    <source src={videoUrl} type="video/mp4" />
                    Tu navegador no soporta el elemento de video.
                  </video>
                )}
              </Box>

              {/* Mostrar botón "Ver en YouTube" si el video es de YouTube */}
              {isYouTubeUrl(videoUrl) && (
                <Button
                  as="a"
                  href={videoUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  leftIcon={<Icon as={FaYoutube} />}
                  bg="#FF0000" // Color rojo YouTube
                  color="white"
                  _hover={{ bg: '#CC0000' }} // Rojo más oscuro al hacer hover
                  _active={{ bg: '#990000' }} // Rojo aún más oscuro al presionar
                  variant="solid"
                  fontFamily="'Space Mono', monospace" // Fuente 'Space Mono'
                >
                  Ver en YouTube
                </Button>
              )}
            </VStack>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ModalVideo;
